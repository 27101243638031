@import "../styles/breakpoints.scss";
@import "../styles/palette.scss";

.container {
  position: relative;
}

.btn {
  opacity: 1;

  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 0px 0px 40px 40px;
  background-color: color(secondary, secondaryOne);
  top: -20px;
  right: 25px;
  z-index: 10;
  transition: opacity 0.3s ease-out;
  @include respond-to("s") {
    height: 80px;
    width: 80px;
    top: -40px;
    right: 80px;
    svg {
      transform: scale(2);
    }
  }
  svg {
    path:nth-child(2) {
      fill: color(primary, primaryOne);
    }
  }
}
